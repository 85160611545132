import React from "react"
import Box from "../Box"
import Card from "../Card"

export default React.memo((props: {
  image: any
  title: string | React.ReactNode
  subtitle?: string | React.ReactNode
  description?: string | React.ReactNode
}) => {
  const {
    image,
    title,
    subtitle,
    description,
  } = props

  return <>
    <Box
      className="is-responsive-3-4 has-half-margin-bottom"
      background={image}
      {...props}
    >
      <Card
        className="is-align-items-center is-text-align-center service-card"
      >
        <article>
          <header>
            <h3>{title}</h3>
            {subtitle && <h4>{subtitle}</h4>}
          </header>

          {description && (
            <p>{description}</p>
          )}
        </article>
      </Card>
    </Box>
  </>;
})
