import React, { useContext } from "react"
import { Helmet } from "react-helmet"
import { PageProps, graphql, Link } from "gatsby"
import Grid from "~/components/Grid/Auto"
import Card from "~/components/Service"
import useScrollToTop from "~/hooks/useScrollToTop"
import useMaxWidth from "~/hooks/useMaxWidth"
import Animated from "~/components/Animated"
import Picture from "~/components/Picture"
import { GatsbyImage, IGatsbyImageData } from "gatsby-plugin-image"
import { I18nContext, useDefaultLanguage } from "~/components/I18n"

export const query = graphql`{
  site {
    siteMetadata {
      title
      description
      author
      image
      siteUrl
    }
  }
  wpMenuItem(url: {regex: "/^\\/services/"}) {
    id
    label
  }
  allWpService(
    sort: {
      order: ASC,
      fields: menuOrder
    }
    filter: {
      status: {
        in: ["publish", "future"]
      }
    }
  ) {
    edges {
      node {
        id
        uri
        date
        title
        language {
          slug
        }
        featuredImage {
          node {
            sourceUrl
            srcSet
            sizes
            mimeType
            localFile {
              childImageSharp {
                gatsbyImageData(width: 800, height: 1066, quality: 92, layout: CONSTRAINED)
              }
            }
          }
        }
      }
    }
    distinct(field: id)
  }
}
`

export default ({
  data: {
    allWpService,
    wpMenuItem: menuItem,
    site: {
      siteMetadata: {
        title,
        description,
        image,
        author,
        siteUrl
      },
    },
  },
}: PageProps<{
  wpMenuItem?: {
    id: string
    label: string
  },
  allWpService: {
    edges: {
      node: {
        id: string
        uri: string
        date: string
        title: string
        language: {
          slug: string
        }
        featuredImage: {
          node: {
            sourceUrl: string
            srcSet: string
            sizes: string
            mimeType: string
            localFile: {
              childImageSharp: {
                gatsbyImageData: IGatsbyImageData
              }
            }
          }
        }
      }
    }[]
  }
  site: {
    siteMetadata: {
      title: string,
      description: string,
      image: string
      author: string
      siteUrl: string
      apiUrl: string
    }
  }
}>) => {
  useScrollToTop()
  useMaxWidth()

  const { locale } = useContext(I18nContext)

  return (
    <>
      <Helmet
        defer={false}
        htmlAttributes={{
          lang: locale,
          dir: "ltr",
          prefix: "og: http://ogp.me/ns#",
        }}
      >
        <title>{title}</title>
        <link rel="author" href="/humans.txt" />

        <meta name="description" content={description} />
        <meta name="image" content={`${siteUrl}${image}`} />

        <meta property="og:title" content={title} />
        <meta property="og:locale" content={locale} />
        <meta property="og:description" content={description} />
        <meta property="og:type" content="website" />
        <meta property="og:image" content={`${siteUrl}${image}`} />

        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content={author} />
        <meta name="twitter:creator" content={author} />
        <meta name="twitter:title" content={title} />
        <meta name="twitter:description" content={description} />
        <meta name="twitter:image" content={`${siteUrl}${image}`} />


      </Helmet>

      <header>
        <h1>{menuItem?.label || "Our Magic"}</h1>
      </header>

      <section>
        <Grid className="has-margin-top">
          {React.Children.toArray(allWpService.edges
            .filter(({ node: { date, language } }) =>
              new Date(date) <= new Date() && (
                language === null || locale === language.slug
              ))
            .map(({
              node: {
                id, uri, title, featuredImage
              }
            }, i) => {
              return (
                <Animated
                  key={id}
                  duration={`500ms`}
                  delay={`${150 * i}ms`}
                >
                  <Link
                    to={uri}
                  >
                    <Card
                      image={featuredImage?.node && (
                        featuredImage.node.localFile?.childImageSharp?.gatsbyImageData ? (
                          <GatsbyImage image={featuredImage.node.localFile.childImageSharp.gatsbyImageData} alt="" />
                        ) : (
                          featuredImage.node.sourceUrl && (
                            <div className="is-responsive-1-1">
                              <Picture src={featuredImage.node.sourceUrl} alt="" sources={[
                                {
                                  srcSet: featuredImage.node.srcSet,
                                  sizes: featuredImage.node.sizes,
                                  mimeType: featuredImage.node.mimeType,
                                }
                              ]} />
                            </div>
                          )
                        )
                      )}
                      title={title}
                    />
                  </Link>
                </Animated>
              )
            }))}
        </Grid>
      </section>
    </>
  )
}
